<template functional>
  <svg
    width="100"
    height="90"
    viewBox="0 0 100 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="vertical-align: top"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M94.1452 75.5269H5.62061C2.45902 75.5269 0 72.9508 0 69.9063V8.8993C0 3.98126 4.09836 0 9.13349 0H90.8665C95.9016 0 100 3.98126 100 8.8993V69.6721C100 72.9508 97.4239 75.5269 94.1452 75.5269ZM9.13349 4.68384C6.67447 4.68384 4.68384 6.55738 4.68384 8.8993V69.9063C4.68384 70.4918 5.15223 70.8431 5.62061 70.8431H94.1452C94.8478 70.8431 95.4333 70.3747 95.4333 69.6721V8.8993C95.4333 6.55738 93.4426 4.68384 90.9836 4.68384H9.13349V4.68384Z"
        fill="#337BDB"
      />
      <path
        d="M97.6581 61.3584H2.34192C1.05386 61.3584 0 60.3045 0 59.0165C0 57.7284 1.05386 56.6746 2.34192 56.6746H97.6581C98.9461 56.6746 100 57.7284 100 59.0165C100 60.3045 98.9461 61.3584 97.6581 61.3584Z"
        fill="#337BDB"
      />
      <path
        d="M61.3583 89.1101C60.3044 89.1101 59.3677 88.4076 59.1335 87.3537L55.8548 73.7706C55.5035 72.4825 56.3232 71.1944 57.6112 70.9602C58.8993 70.609 60.1873 71.4286 60.4215 72.7167L63.7002 86.2998C64.0515 87.5879 63.2318 88.8759 61.9438 89.1101C61.7096 89.1101 61.5925 89.1101 61.3583 89.1101Z"
        fill="#337BDB"
      />
      <path
        d="M39.2272 89.1101C38.993 89.1101 38.8759 89.1101 38.6417 88.993C37.3536 88.6417 36.6511 87.3537 37.0024 86.0656L40.6323 72.5996C40.9836 71.3115 42.2717 70.6089 43.5597 70.9602C44.8478 71.3115 45.5504 72.5996 45.1991 73.8876L41.5691 87.4708C41.2178 88.4075 40.281 89.1101 39.2272 89.1101Z"
        fill="#337BDB"
      />
      <path
        d="M70.4918 89.1101H29.5082C28.2201 89.1101 27.1663 88.0562 27.1663 86.7682C27.1663 85.4801 28.2201 84.4263 29.5082 84.4263H70.4918C71.7798 84.4263 72.8337 85.4801 72.8337 86.7682C72.8337 88.0562 71.7798 89.1101 70.4918 89.1101Z"
        fill="#337BDB"
      />
      <path
        d="M50.3513 50.3513C60.6986 50.3513 69.0867 41.9632 69.0867 31.616C69.0867 21.2687 60.6986 12.8806 50.3513 12.8806C40.0041 12.8806 31.616 21.2687 31.616 31.616C31.616 41.9632 40.0041 50.3513 50.3513 50.3513Z"
        fill="#EFF7FC"
        stroke="#337BDB"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M50.3513 22.2483V32.6568"
        stroke="#337BDB"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M50.3513 39.4224V40.203"
        stroke="#337BDB"
        stroke-width="4"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="100" height="89.1101" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: 'ImgInternalError' }
</script>
