
import Vue from 'vue'

import ImgInternalError from '@/components/images/ImgInternalError.vue'

export default Vue.extend({
  name: 'NotAuthorized',
  components: { ImgInternalError },
  data() {
    return {
      contactSupportLink: `mailto:${Vue.$appSettings.supportEmail}`,
    }
  },
  methods: {
    reload() {
      location.href = '/'
    },
  },
})
